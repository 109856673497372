const MOVIES_REQUEST_PENDING = "MOVIES_REQUEST_PENDING";
const MOVIES_REQUEST_SUCCESS = "MOVIES_REQUEST_SUCCESS";
const MOVIES_REQUEST_FAILURE = "MOVIES_REQUEST_FAILURE";
const MOVIE_CREATION_PENDING = "MOVIE_CREATION_PENDING";
const MOVIE_CREATION_SUCCESS = "MOVIE_CREATION_SUCCESS";
const MOVIE_CREATION_FAILURE = "MOVIE_CREATION_FAILURE";
const MOVIE_UPDATE_PENDING = "MOVIE_UPDATE_PENDING";
const MOVIE_UPDATE_SUCCESS = "MOVIE_UPDATE_SUCCESS";
const MOVIE_UPDATE_FAILURE = "MOVIE_UPDATE_FAILURE";
const MOVIE_DELETE_PENDING = "MOVIE_DELETE_PENDING";
const MOVIE_DELETE_SUCCESS = "MOVIE_DELETE_SUCCESS";
const MOVIE_DELETE_FAILURE = "MOVIE_DELETE_FAILURE";
const MOVIE_SEARCH_PENDING = "MOVIE_SEARCH_PENDING";
const MOVIE_SEARCH_SUCCESS = "MOVIE_SEARCH_SUCCESS";
const MOVIE_SEARCH_FAILURE = "MOVIE_SEARCH_FAILURE";
const RESET_PROPOSAL_LIST = "RESET_PROPOSAL_LIST";
const MOVIE_SYNC = "MOVIE_SYNC";
const PAGINATE_ITEMS = "PAGINATE_ITEMS";

export {
  MOVIES_REQUEST_PENDING,
  MOVIES_REQUEST_SUCCESS,
  MOVIES_REQUEST_FAILURE,
  MOVIE_CREATION_PENDING,
  MOVIE_CREATION_SUCCESS,
  MOVIE_CREATION_FAILURE,
  MOVIE_UPDATE_PENDING,
  MOVIE_UPDATE_SUCCESS,
  MOVIE_UPDATE_FAILURE,
  MOVIE_DELETE_PENDING,
  MOVIE_DELETE_SUCCESS,
  MOVIE_DELETE_FAILURE,
  MOVIE_SEARCH_PENDING,
  MOVIE_SEARCH_SUCCESS,
  MOVIE_SEARCH_FAILURE,
  RESET_PROPOSAL_LIST,
  MOVIE_SYNC,
  PAGINATE_ITEMS
};
